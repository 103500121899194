import React, { Component } from "react";
import "./farmDetails.scss";

export default class FarmDetails extends Component {
    render() {
        return (
            <div className="farmDetails--form">
                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">Farm Info</div>
                    <div className="panel-body">
                        <p>
                            To edit farm details visit{" "}
                            <a
                                href={`https://app.blue-trace.com/${this.props._id}/settings/qr-code`}
                                target="_blank"
                            >
                                app.blue-trace.com
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
