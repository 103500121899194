import Constants from "../constants";
import ObjectAssign from "object-assign";
import Moment from "moment";

const initialState = {
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    showLeaseSaveSuccess: false,
    showLineSaveSuccess: false,
    showNewLine: false,
    error: undefined,
    hasError: {},
    help: {},
    _id: undefined,
    name: undefined,
    owner: undefined,
    leases: [],
    address1: "",
    address2: "",
    city: "",
    containerOptions: {},
    state: "",
    country: "",
    activities: [],
    email: "",
    zip: "",
    type: "farm",
    activeTab: 0,
    tabs: [],
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.ADD_FARM_DETAILS) {
        return ObjectAssign({}, state, {
            loading: true,
        });
    }

    if (action.type === Constants.ADD_FARM_DETAILS_RESPONSE) {
        // const validation = ParseValidation(action.response);

        // eslint-disable-next-line
        const leasesWithDates = action.response.leases.map((lease) => {
            lease.dateOfIssue = Moment(lease.dataOfIssue);
            lease.dateOfExpiration = Moment(lease.dateOfExpiration);
            return lease;
        });

        const stateUpdates = {
            _id: action.response._id,
            loading: false,
            showSaveSuccess: true,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            activities: action.response.activities,
            email: action.response.email,
            name: action.response.name,
            owner: action.response.owner,
            state: action.response.state,
            type: action.response.type,
            zip: action.response.zip,
            leases: action.response.leases,
            setupFee: action.response.setupFee,
            activeTab: 1,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.LOAD_FARM_DATA) {
        const leasesWithDates = action.data.leases.map((lease) => {
            lease.dateOfIssue = Moment(lease.dataOfIssue);
            lease.dateOfExpiration = Moment(lease.dateOfExpiration);
            return lease;
        });

        const stateUpdates = {
            _id: action.data._id,
            loading: false,
            address1: action.data.address1,
            address2: action.data.address2,
            city: action.data.city,
            country: action.data.country,
            activities: action.data.activities,
            email: action.data.email,
            name: action.data.name,
            owner: action.data.owner,
            type: action.data.type,
            setupFee: action.data.setupFee,
            state: action.data.state,
            zip: action.data.zip,
            leases: leasesWithDates,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.TABS_ADD) {
        const newState = ObjectAssign({}, state, {
            tabs: action.tabs,
        });
        return newState;
    }

    if (action.type === Constants.TOGGLE_TAB) {
        return ObjectAssign({}, state, {
            activeTab: action.activeTab,
        });
    }

    return state;
};

export default reducer;
