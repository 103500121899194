import React, { useEffect } from "react";

const activityLookup = [
    {
        _id: "60d383cb40c7356d769bee1c",
        name: "Harvest",
    },
    {
        _id: "60d39241b14ab7713479219d",
        name: "Order",
    },
    {
        _id: "61771b7df83146803021ab05",
        name: "Outgoing",
    },
    {
        _id: "671fe4213a3793406a00e75a",
        name: "Process",
    },
    {
        _id: "60fc663b59b93a0133efc10a",
        name: "Receive",
    },
    {
        _id: "6195292a3e95fbea08f68ffd",
        name: "Record",
    },
];

const defaultFarmActivities = ["Harvest"];
const defaultDistributorActivities = ["Receive", "Order"];
const distributorActivities = ["Receive", "Order", "Process"];

export const Checkbox = ({ label, value, onChange, disabled }) => {
    return (
        <label>
            <input
                type={"checkbox"}
                checked={value}
                onChange={onChange}
                disabled={disabled}
                style={{ marginRight: "5px" }}
            />
            {label}
        </label>
    );
};

export default function ActivityCheckboxes({
    activities,
    updateActivities,
    setInitialActivities,
    farmType,
}) {
    useEffect(() => {
        if (farmType === "farm") {
            setInitialActivities(
                activityLookup
                    .filter((a) => a.name === "Harvest")
                    .map((a) => a._id)
            );
        } else {
            setInitialActivities(
                activityLookup
                    .filter((a) => a.name === "Receive" || a.name === "Order")
                    .map((a) => a._id)
            );
        }
    }, [farmType]);

    const isCheckboxDisabled = (farmType, activityName) => {
        if (farmType === "farm") {
            return distributorActivities.includes(activityName);
        }
        return !distributorActivities.includes(activityName);
    };
    return (
        <div className="form-group">
            <label className="control-label">Tagging Activities</label> <br />
            {activityLookup.map((act) => (
                <>
                    <Checkbox
                        label={act.name}
                        value={activities.includes(act._id)}
                        onChange={() => {
                            updateActivities(act._id);
                        }}
                        disabled={isCheckboxDisabled(farmType, act.name)}
                    />
                    <br />
                </>
            ))}
        </div>
    );
}
