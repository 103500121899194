import Footer from './footer.jsx';
import Navbar from './navbar.jsx';
import NotFound from './not-found.jsx';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FarmDetails from './farms/details/index.jsx';
import FarmSearch from './farms/search/index.jsx';
import FarmWizard from './farms/wizard/index.jsx';
import FarmUpload from './farms/upload/index.jsx';
import CheckPermission from '../../components/CheckPermission.js';

const App = () => {
	return (
		<div>
			<CheckPermission />
			<Route component={Navbar} />
			<Switch>
				<Route path="/farms" exact component={FarmSearch} />
				<Route path="/farms/setup" exact component={FarmWizard} />
				<Route path="/farms/upload/:id" component={FarmUpload} />
				<Route path="/farms/:id" component={FarmDetails} />
				<Route component={NotFound} />
			</Switch>
			<Footer />
		</div>
	);
};

export default App;
