import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../helpers/parse-validation";

const initialState = {
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    showDetailsSaveSuccess: false,
    showNewLine: false,
    error: undefined,
    distributorBrands: [],
    growerNames: "",
    photo: null,
    setupFee: 0,
    stripeCustomerId: null,
    taggingActive: false,
    taggingSetupInvoice: "",
    hasError: {},
    help: {},
    _id: undefined,
    name: undefined,
    owner: undefined,
    leases: [],
    address1: "",
    address2: "",
    city: "",
    country: "",
    email: "",
    state: "",
    zip: "",
    activeTab: 0,
    tabs: [],
    editMode: false,
    totalPrinted: 0,
    viewByOptions: {},
};
const reducer = function (state = initialState, action) {
    if (action.type === Constants.TOGGLE_SAVE_SUCCESS) {
        return ObjectAssign({}, state, {
            showSaveSuccess: action.showSaveSuccess,
        });
    }

    if (action.type === Constants.GET_DETAILS) {
        return ObjectAssign({}, state, {
            hydrated: false,
            loading: true,
        });
    }

    if (action.type === Constants.GET_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);

        const leases =
            (action.response.leases && action.response.leases.reverse()) || [];

        const stateUpdates = {
            _id: action.response._id,
            hydrated: true,
            loading: false,
            showFetchFailure: !!action.err,
            error: validation.error,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            email: action.response.email,
            name: action.response.name,
            owner: action.response.owner,
            leases,
            setupFee: action.response.setupFee,
            state: action.response.state,
            zip: action.response.zip,
            stripeCusotmerId: action.response.stripeCusotmerId,
            taggingActive: action.response.taggingActive,
            refillRollCount: action.response.refillRollCount,
            paperSize: action.response.paperSize,
            taggingSetupInvoice: action.response.taggingSetupInvoice,
            taggingRefillThreshold: action.response.taggingRefillThreshold,
            taggingSetupFee: action.response.taggingSetupFee,
            taggingInvoiceAmount: action.response.taggingInvoiceAmount,
            refillSoftwareFee: action.response.refillSoftwareFee,
            taggingInvoices: action.response.taggingInvoices,
            shippingAddress: action.response.shippingAddress,
            totalPrinted: action.response.totalPrinted,
            distributorBrands: action.response.distributorBrands || [],
            growerNames: action.response.growerNames,
            photo: action.response.photo,
        };

        return ObjectAssign({}, state, stateUpdates);
    }
    if (action.type === Constants.SAVE_FARM_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);

        const leases = action.response.leases.reverse();

        const stateUpdates = {
            showDetailsSaveSuccess: true,
            _id: action.response._id,
            hydrated: true,
            loading: false,
            showFetchFailure: !!action.err,
            error: validation.error,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            email: action.response.email,
            name: action.response.name,
            owner: action.response.owner,
            leases,
            setupFee: action.response.setupFee,
            state: action.response.state,
            zip: action.response.zip,
            stripeCusotmerId: action.response.stripeCusotmerId,
            taggingActive: action.response.taggingActive,
            taggingSetupInvoice: action.response.taggingSetupInvoice,
            taggingRefillThreshold: action.response.taggingRefillThreshold,
            taggingSetupFee: action.response.taggingSetupFee,
            taggingInvoiceAmount: action.response.taggingInvoiceAmount,
            refillSoftwareFee: action.response.refillSoftwareFee,
            refillRollCount: action.response.refillRollCount,
            paperSize: action.response.paperSize,
            taggingInvoices: action.response.taggingInvoices,
            shippingAddress: action.response.shippingAddress,
            totalPrinted: action.response.totalPrinted,
            distributorBrands: action.response.distributorBrands || [],
            growerNames: action.response.growerNames,
            photo: action.response.photo,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.GET_FILTERS_RESPONSE) {
        const stateUpdates = {
            viewByOptions: action.response.filters,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.TOGGLE_EDIT_MODE) {
        return ObjectAssign({}, state, {
            editMode: action.toggle,
        });
    }

    if (
        action.type === Constants.SAVE_DETAILS ||
        action.type === Constants.SAVE_FARM_DETAILS
    ) {
        return ObjectAssign({}, state, {
            loading: true,
            name: action.request.data.name,
            location: action.request.data.location,
        });
    }

    if (action.type === Constants.SAVE_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);
        const stateUpdates = {
            loading: false,
            showSaveSuccess: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help,
        };

        if (action.response.hasOwnProperty("name")) {
            stateUpdates.name = action.response.name;
        }
        if (action.response.hasOwnProperty("location")) {
            stateUpdates.location = action.response.location;
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.HIDE_DETAILS_SAVE_SUCCESS) {
        return ObjectAssign({}, state, {
            showSaveSuccess: false,
            showDetailsSaveSuccess: false,
        });
    }

    if (action.type === Constants.SAVE_ADDRESS_RESPONSE) {
        return ObjectAssign({}, state, {
            shippingAddress: action.response.shippingAddress,
        });
    }

    if (action.type === Constants.SAVE_FARM_DETAILS_RESPONSE) {
        // console.log("action", action.response);
    }

    return state;
};

export default reducer;
