import ApiActions from "../../../../actions/api";
import Constants from "./constants";
import Store from "./store";

class Actions {
    static saveFarmInfo(
        address1,
        address2,
        city,
        name,
        companyName,
        owner,
        setupFee,
        state,
        country,
        activities,
        email,
        zip,
        type
    ) {
        const data = {
            address1,
            address2,
            city,
            name,
            companyName,
            owner,
            setupFee,
            state,
            country,
            activities,
            email,
            zip,
            type,
        };

        ApiActions.post(
            "/api/farms",
            data,
            Store,
            Constants.ADD_FARM_DETAILS,
            Constants.ADD_FARM_DETAILS_RESPONSE
        );
    }

    static updateFarmInfo(
        farmId,
        address1,
        address2,
        city,
        name,
        companyName,
        owner,
        state,
        country,
        activities,
        email,
        zip,
        type
    ) {
        const data = {
            address1,
            address2,
            city,
            name,
            companyName,
            owner,
            state,
            country,
            activities,
            email,
            zip,
            type,
        };

        ApiActions.put(
            `/api/farms/${farmId}`,
            data,
            Store,
            Constants.ADD_FARM_DETAILS,
            Constants.ADD_FARM_DETAILS_RESPONSE
        );
    }

    static loadFarmData(data) {
        console.log("load farm data");
        return Store.dispatch({
            type: Constants.LOAD_FARM_DATA,
            data,
        });
    }

    static addTabs(tabs) {
        return Store.dispatch({
            type: Constants.TABS_ADD,
            tabs,
        });
    }
    static toggleTab(activeTab) {
        return Store.dispatch({
            type: Constants.TOGGLE_TAB,
            activeTab,
        });
    }
}

export default Actions;
