import React from 'react';
import { Helmet } from 'react-helmet';
import NavBar from './navbar.jsx';
import JsonFetch from '../../helpers/json-fetch';
import check from '../../media/harvestInfoCheck.svg';
import { format } from 'date-fns';

function removeNBSP(str) {
	return str.replace(/\xa0/g, ' ');
}

function dateToYMD(date) {
	const utcDate = date.toLocaleString('en-US', {
		timeZone: 'UTC',
	});
	return format(new Date(utcDate), 'd MMM yyyy');
}

function dateFromAMPM(time, startDate = new Date()) {
	// eslint-disable-next-line prefer-const

	if (!time) {
		time = '12:00 am';
	}

	let [hours, minutes] = time
		.substr(0, time.length - 2)
		.split(':')
		.map(Number);

	if (time.includes('pm') && hours !== 12) {
		hours += 12;
	}

	if (time.includes('am') && hours === 12) {
		hours = 0;
	}

	const date = new Date(startDate);
	date.setHours(hours);
	date.setMinutes(minutes);

	// HACK ALERT: This is a fix for AK timezones that are OBO when displayed and then returned from the date picker. Companion hack in features/Form/components/DatePicker.js
	// UPDATE AS OF NOV 11 2021 this hack doesn't seem like its needed anymore so commenting it out but keeping the code around just b/c
	// if (date.getTimezoneOffset() === 480 && !date.isDstObserved()) {
	//   date.setHours(date.getHours() + 1)
	// }

	return date;
}

const FarmInfo = ({ farm, tag, parentTag }) => {
	const { dealer, photo, growerNames, distributorBrands } = farm;

	const addressInfo =
		(tag.certId && farm.certs.find((cert) => cert.certId === tag.certId)) ||
		farm;

	console.log('address', addressInfo);
	const addressLines = [];
	if (addressInfo.phone) {
		addressLines.push(addressInfo.phone);
	}
	if (addressInfo.website) {
		let link = addressInfo.website;
		var prefix = 'http://';
		if (link.substr(0, prefix.length) !== prefix) {
			link = prefix + link;
		}
		addressLines.push(`<a href="${link}">${addressInfo.website}</a>`);
	}

	const address = [
		addressInfo.address1,
		addressInfo.address2,
		addressInfo.city,
		addressInfo.state,
		addressInfo.zip,
		addressInfo.country,
	];

	addressLines.push(
		address
			.filter(Boolean)
			.map((a) => removeNBSP(a))
			.join(', ')
	);

	if (tag.type === 'FARM' && (addressInfo.cert || addressInfo.harvesterCert)) {
		addressLines.push(`CERT# ${addressInfo.cert || addressInfo.harvesterCert}`);
	} else if (addressInfo.cert || tag.cert) {
		addressLines.push(`CERT# ${addressInfo.cert || tag.cert}`);
	}

	const name =
		farm.type === 'distributor'
			? growerNames || farm.name
			: removeNBSP(addressInfo.name);
	const brand =
		tag.item ||
		(tag.brand &&
			distributorBrands &&
			distributorBrands
				.filter((b) => !!b.brand)
				.find((b) => {
					return b.brand?.trim() === tag.brand.trim();
				})) ||
		null;

	const speciesLabel = [
		'a7b07c0b-9bb4-4ca2-9771-c66e09ee4e2b',
		'b16c51bc-1e1e-44df-82c9-b6025233c619',
		'392b3e78-d991-4273-8ca3-a40bb5b439fa',
	].includes(farm._id)
		? 'Type'
		: 'Species';
	return (
		<div className="container--farm">
			<h1>{name}</h1>
			<p>
				{addressLines.map((l) => {
					return (
						<span>
							<span dangerouslySetInnerHTML={{ __html: l }} />
							<br />
						</span>
					);
				})}
			</p>
			{tag.brand && (
				<>
					<h4>Brand</h4>
					<p>{removeNBSP(tag.brand)}</p>
				</>
			)}
			{(tag.shellfishType || (tag.species && tag.species.value)) && (
				<>
					<h4>{speciesLabel}</h4>
					<p>
						{removeNBSP(
							(tag.species && tag.species.value) || tag.shellfishType
						)}
					</p>
				</>
			)}

			{tag.sizeCull && (
				<>
					<h4>Size</h4>
					<p>{removeNBSP(tag.sizeCull)}</p>
				</>
			)}

			{!(parentTag && parentTag.farm.growerNames) &&
				growerNames &&
				farm.type !== 'distributor' && (
					<>
						<h4>Grown By</h4>
						<p>{growerNames}</p>
					</>
				)}
			{!parentTag && brand && brand.growingProcess && (
				<>
					<h4>Growing Process</h4>
					<p>{brand.growingProcess}</p>
				</>
			)}
			{!parentTag && brand && brand.tastingNotes && (
				<>
					<h4>Tasting Notes</h4>
					<p>{brand.tastingNotes}</p>
				</>
			)}
		</div>
	);
};

const TagInfo = ({ printItem }) => {
	const { farm, rootTag, recall } = printItem;
	let item = {};
	if (printItem.tag.item) {
		item = Object.entries(printItem.tag.item)
			.filter(([, value]) => value !== null)
			.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
	}

	const tag = { ...printItem.tag, ...item };

	let { photo } = farm;

	const harvestInfo = [];
	if (tag.type !== 'DISTRIBUTOR' && tag.sourceCert) {
		harvestInfo.push({
			title: 'Original Shipper',
			data: tag.sourceCert,
			date: dateFromAMPM(tag.harvestTime, new Date(tag.harvestDate)),
			sort: 2,
		});
	}

	if (tag.type === 'DISTRIBUTOR' && tag.sourceCert) {
		harvestInfo.push({
			title: tag.vendorType !== 'Harvester' ? 'Original Shipper' : 'Harvester',
			data: `${tag.sourceCert}`,
			date: dateFromAMPM(tag.harvestTime, new Date(tag.harvestDate)),
			sort: 2,
		});
	}

	const date =
		farm._id === '2f5d6ec2-75a9-4dac-82a6-65bb00a4c45d'
			? tag.processingDate
			: tag.harvestDate;
	if (date || tag.harvestLocation) {
		harvestInfo.push({
			title:
				farm._id === '2f5d6ec2-75a9-4dac-82a6-65bb00a4c45d'
					? 'Processing Date'
					: 'Harvest',
			data: `${
				[tag.harvestLocation, tag.parcel].filter(Boolean).join(', ') || ''
			} <br/> ${(date && dateToYMD(new Date(date))) || ''} ${
				tag.harvestTime || ''
			}`,
			date: dateFromAMPM(tag.harvestTime, new Date(date)),
			sort: 1,
		});
	}

	if (tag.icingDate || tag.icingTime) {
		harvestInfo.push({
			title: 'Ice/Refrigeration',
			data: `${dateToYMD(new Date(tag.icingDate || tag.harvestDate))}${
				(tag.icingTime && `- ${tag.icingTime}`) || ''
			}`,
			date: dateFromAMPM(null, new Date(tag.icingDate)),
		});
	}

	if (tag.receiptDate) {
		harvestInfo.push({
			title: 'Received Date',
			data: `${dateToYMD(new Date(tag.receiptDate))}${
				tag.receiptTime ? ` - ${tag.receiptTime}` : ''
			}`,
			date: dateFromAMPM(tag.receiptTime, new Date(tag.receiptDate)),
		});
	}

	if (tag.wetStorageLot) {
		harvestInfo.push({
			title: 'Wet Storage Lot #',
			data: tag.wetStorageLot,
			date: dateFromAMPM(
				null,
				new Date(tag.wetStorageDate || tag.wetStorageEndDate)
			),
		});
	}

	if (tag.wetStorageDate) {
		const wetStorageData = dateToYMD(new Date(tag.wetStorageDate));

		harvestInfo.push({
			title: 'Wet Storage Start Date',
			data: wetStorageData,
			date: dateFromAMPM(null, new Date(tag.wetStorageDate)),
		});
	}

	if (tag.wetStorageEndDate) {
		const wetStorageData = dateToYMD(new Date(tag.wetStorageEndDate));

		harvestInfo.push({
			title: 'Wet Storage End Date',
			data: wetStorageData,
			date: dateFromAMPM(null, new Date(tag.wetStorageEndDate)),
		});
	}

	if (tag.destination || tag.shipDate) {
		harvestInfo.push({
			title: 'Ship',
			data: `${tag.destination || ''}${
				tag.destination && tag.shipDate ? '<br/>' : ''
			}${
				tag.shipDate
					? `${dateToYMD(new Date(tag.shipDate))}${
							tag.shipTime ? `- ${tag.shipTime}` : ''
					  }`
					: ``
			}`,
			date: dateFromAMPM(tag.shipTime, new Date(tag.shipDate)),
		});
	}

	return (
		<>
			{recall && (
				<p
					style={{
						backgroundColor: '#FF561C',
						color: '#fff',
						marginLeft: '-40px',
						marginRight: ' -40px',
						marginBottom: 0,
						padding: '10px 40px',
						fontWeight: 700,
					}}
				>{`PRODUCT RECALLED BY ${recall}.`}</p>
			)}
			{photo && (
				<div
					style={{
						background: `url(${photo}) top center no-repeat #ccc`,
						height: '300px',
						marginLeft: '-40px',
						marginRight: '-40px',
						backgroundSize: 'auto 100%',
					}}
				></div>
			)}
			<div className="flex">
				<div className="container--tag">
					<FarmInfo farm={farm} tag={tag} parentTag={rootTag} />
				</div>

				<div className="container--harvestInfo">
					{harvestInfo
						.sort((a, b) => {
							if (a.sort && !b.sort) {
								return -1;
							}
							if (b.sort && !a.sort) {
								return 1;
							}

							if (a.sort && b.sort) {
								return a.sort > b.sort ? 1 : -1;
							}

							if (a.date.getTime() === b.date.getTime()) {
								console.log('compare', a, b);
								// this is weird but if dates are the same wet storage should always come before ship.
								// probably a smarter way to do this... but here we are...
								if (a.title.includes('Ship') && b.title.includes('Wet')) {
									return 1;
								}
								if (a.title.includes('Wet') && b.title.includes('Ship')) {
									return -1;
								}
								if (a.title.includes('End') && b.title.includes('Start')) {
									return 1;
								}
								if (a.title.includes('Start') && b.title.includes('End')) {
									return 1;
								}

								if (a.title.includes('Wet') && b.title.includes('Ice')) {
									return 1;
								}
								if (a.title.includes('Ice') && b.title.includes('Wet')) {
									return -1;
								}
							}

							return a.date.getTime() > b.date.getTime() ? 1 : -1;
						})
						.map((h, i) => {
							const lastClass =
								harvestInfo.length - 1 === i ? 'harvestInfoItem__last' : '';
							return (
								<div className={`harvestInfoItem ${lastClass}`}>
									<img src={check} alt="check" />
									<h4>{h.title}</h4>

									<p dangerouslySetInnerHTML={{ __html: h.data }} />
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

const Error = ({ statusCode, msg }) => {
	console.log('error thing', statusCode, msg);
	if (statusCode === 404) {
		return (
			<div>
				<h1>Tag not found</h1>
				<p>We're sorry. The tag you scanned could not be found.</p>
			</div>
		);
	}

	return (
		<div>
			<h1>An Error Occured</h1>
			<p>We're sorry. Something went wrong looking for your tag.</p>
		</div>
	);
};

class TagPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			printItem: null,
			error: null,
		};
	}

	componentWillMount() {
		const path = window.location.pathname
			.split('/')
			.filter(Boolean)
			.slice(0, 2)
			.join('/');
		const request = {
			method: 'GET',
			url: `/api/${path}`,
			query: {},
		};

		JsonFetch(request, (err, printItem) => {
			if (err) {
				this.setState({ error: printItem.statusCode });

				return;
			}

			window.analytics.track('Tag Pageview', {
				farm: printItem.farm.name,
				farmId: printItem.farm._id,
				tagId: printItem.tag._id,
			});

			document.title = `BlueTrace - ${removeNBSP(
				printItem.farm.name
			)} Harvest Tag Information`;
			this.setState({ printItem });
		});
	}

	render() {
		return (
			<div>
				<Helmet>
					<body class="tagPage" />
				</Helmet>
				<NavBar />

				<div className="container container--farmInfo">
					{(this.state.error && <Error statusCode={this.state.error} />) ||
						null}

					{(this.state.printItem && (
						<TagInfo printItem={this.state.printItem} />
					)) ||
						null}
				</div>
			</div>
		);
	}
}

export default TagPage;
