import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "ADD_FARM_DETAILS",
    "ADD_FARM_DETAILS_RESPONSE",
    "LOAD_FARM_DATA",
    "SAVE_TOGGLE",
    "TABS_ADD",
    "TOGGLE_TAB",
]);
