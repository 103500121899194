import React from "react";
import moment from "moment";
import Actions from "./actions";
import TaggingBillingDetails from "./TaggingBillingDetails";
import TaggingShipmentHistory from "./TaggingShipmentHistory";
import NextShipment from "./NextShipment";
import CustomPaperHistory from "./CustomPaperHistory";

class TaggingSummary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { type } = this.props;

        const shippingAddress = this.props.shippingAddress || {};

        const { taggingRefillThreshold, totalPrinted } = this.props;

        return (
            <div>
                <br />
                <div className="panel panel-default">
                    <div className="panel-heading">Overview</div>
                    <ul className="list-group list-group-farm-summary">
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Paper Size:</dt>
                            <dd className="col-sm-8">{this.props.paperSize}</dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Printers:</dt>
                            <dd className="col-sm-8">
                                {(this.props.printers &&
                                    this.props.printers.map((printer, i) => {
                                        const paper = [
                                            printer.rollCount &&
                                                `${printer.rollCount} tag rolls`,
                                            printer.stickerRollCount &&
                                                `${printer.stickerRollCount} sticker rolls`,
                                            printer.blackLineMobile &&
                                                `${printer.blackLineMobile} black line mobile rolls`,
                                        ]
                                            .filter(Boolean)
                                            .join(", ");
                                        return (
                                            <>
                                                &bull; {printer.name} (
                                                {printer.type}) + {paper}
                                                <br />
                                            </>
                                        );
                                    })) ||
                                    null}
                            </dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Paper Consumption:</dt>

                            <dd className="col-sm-8">
                                <TaggingBillingDetails
                                    totalPrinted={totalPrinted}
                                    taggingRefillThreshold={
                                        taggingRefillThreshold
                                    }
                                    customPaperRolls={
                                        this.props.customPaperRolls
                                    }
                                    customMobileRolls={
                                        this.props.customMobileRolls
                                    }
                                    paperCosts={this.props.paperCosts}
                                />
                            </dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Shipment History:</dt>
                            <dd className="col-sm-8">
                                <TaggingShipmentHistory
                                    taggingInvoices={this.props.taggingInvoices}
                                />
                                {(this.props.customPaperRolls &&
                                    this.props.customPaperRolls.length && (
                                        <>
                                            <h4>Custom Industrial Rolls</h4>
                                            <CustomPaperHistory
                                                customPaperRolls={
                                                    this.props.customPaperRolls
                                                }
                                                paperCosts={
                                                    this.props.paperCosts
                                                }
                                                type="industrial"
                                            />
                                        </>
                                    )) ||
                                    null}
                                {(this.props.customMobileRolls &&
                                    this.props.customMobileRolls.length && (
                                        <>
                                            <h4>Custom Mobile Rolls</h4>
                                            <CustomPaperHistory
                                                customPaperRolls={
                                                    this.props.customMobileRolls
                                                }
                                                paperCosts={
                                                    this.props.paperCosts
                                                }
                                                type="mobileCustom"
                                            />
                                        </>
                                    )) ||
                                    null}
                            </dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Next Shipment:</dt>
                            <dd className="col-sm-8">
                                <NextShipment
                                    taggingRefillThreshold={
                                        taggingRefillThreshold
                                    }
                                    {...this.props}
                                />
                            </dd>
                        </li>
                    </ul>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">Refill Shipping Info</div>
                    <ul className="list-group list-group-farm-summary">
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Name:</dt>
                            <dd>{shippingAddress.name}</dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Business:</dt>
                            <dd>{shippingAddress.business}</dd>
                        </li>
                        <li className="list-group-item row">
                            <dt className="col-sm-4">Address:</dt>
                            <dd style={{ overflow: "hidden" }}>
                                {shippingAddress.address1}
                                {(shippingAddress.business && <br />) || null}
                                {shippingAddress.business}
                                {(shippingAddress.address2 && <br />) || null}
                                {shippingAddress.address2}
                                <br />
                                {shippingAddress.city}, {shippingAddress.state}{" "}
                                {shippingAddress.zip}
                                {shippingAddress.country && <br />}
                                {shippingAddress.country}
                            </dd>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default TaggingSummary;
