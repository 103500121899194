import Constants from "../constants";
import ObjectAssign from "object-assign";
import ParseValidation from "../../../../../helpers/parse-validation";

const initialState = {
    activeTab: 0,
    hydrated: false,
    loading: false,
    showFetchFailure: false,
    showSaveSuccess: false,
    showDetailsSaveSuccess: false,
    showNewLine: false,
    error: undefined,

    setupFee: 0,
    hasError: {},
    help: {},
    _id: undefined,
    name: undefined,
    companyName: undefined,
    owner: undefined,
    taggingActive: false,
    leases: [],
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    activities: [],
    email: "",
    zip: "",
    tabs: [],
    editMode: false,
    taggingSetupFee: null,
    promotionalFee: null,
    taggingInvoiceAmount: null,
    taggingRefillThreshold: null,
    paperSize: '7"',
    refillSoftwareFee: null,
    taggingInvoices: [],
    stripeCustomerId: null,
    hasPaymentMethod: false,
    totalPrinted: 0,
    viewByOptions: {},
    type: "",
    sendFullfillmentEmail: true,
    paperDiscount: false,
    distributorBrands: [],
    growerNames: "",
    photo: null,

    printers: [],
    paperCosts: {},
    users: [],
    hasCustomPaper: false,
    customPaperRolls: null,
    customMobileRolls: null,
    nextMobileShipment: null,
    nextIndustrialShipment: null,
    mobileLaminatedRollRefillCount: null,
    sixMilMobileRollRefillCount: null,
    industrialSmallRollRefillCount: null,
    features: {},
};

const reducer = function (state = initialState, action) {
    if (action.type === Constants.GET_DETAILS) {
        return ObjectAssign({}, initialState, {
            hydrated: false,
            loading: true,
        });
    }

    if (
        action.type === Constants.GET_DETAILS_RESPONSE ||
        action.type === Constants.ENABLE_TAGGING_RESPONSE
    ) {
        const validation = ParseValidation(action.response);

        if (validation.error) {
            return ObjectAssign({}, state, {
                loading: false,
                hydrated: true,
                error: validation.error,
                showFetchFailure: validation.error,
            });
        }

        const leases = action.response.leases.reverse();

        const stateUpdates = {
            activeTab:
                action.type === Constants.ENABLE_TAGGING_RESPONSE ? 2 : 0,
            stripeCustomerId: action.response.stripeCustomerId,
            hasPaymentMethod: action.response.hasPaymentMethod,
            _id: action.response._id,
            hydrated: true,
            loading: false,
            showFetchFailure: !!action.err,
            error: validation.error,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            activities: action.response.activities || [],
            email: action.response.email,
            name: action.response.name,
            companyName: action.response.companyName,
            owner: action.response.owner,
            leases,
            taggingActive: action.response.taggingActive,
            setupFee: action.response.setupFee,
            state: action.response.state,
            zip: action.response.zip,
            taggingRefillThreshold: action.response.taggingRefillThreshold,
            taggingSetupFee: action.response.taggingSetupFee,
            promotionalFee: action.response.promotionalFee,
            taggingInvoiceAmount: action.response.taggingInvoiceAmount,
            paperSize: action.response.paperSize,
            taggingSetupInvoice: action.response.taggingSetupInvoice,
            refillSoftwareFee: action.response.refillSoftwareFee,
            taggingInvoices: action.response.taggingInvoices,
            shippingAddress: action.response.shippingAddress,
            type: action.response.type,
            emailSent: false,
            totalPrinted: action.response.totalPrinted,
            sendFullfillmentEmail: action.response.sendFullfillmentEmail,
            paperDiscount: action.response.paperDiscount,
            distributorBrands: action.response.distributorBrands || [],
            growerNames: action.response.growerNames,
            photo: action.response.photo,
            printers: action.response.printers,
            mobileRollRefillCount: action.response.mobileRollRefillCount,
            industrialRollRefillCount:
                action.response.industrialRollRefillCount,
            mobileStickerRollRefillCount:
                action.response.mobileStickerRollRefillCount,
            users: action.response.users,
            blackLineMobileRefillCount:
                action.response.blackLineMobileRefillCount,
            paperCosts: action.response.paperCosts,
            hasCustomPaper: action.response.hasCustomPaper || false,
            customPaperRolls: action.response.customPaperRolls || null,
            customMobileRolls: action.response.customMobileRolls || null,
            nextMobileShipment: action.response.nextMobileShipment,
            nextIndustrialShipment: action.response.nextIndustrialShipment,
            mobileLaminatedRollRefillCount:
                action.response.mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount:
                action.response.sixMilMobileRollRefillCount,
            industrialSmallRollRefillCount:
                action.response.industrialSmallRollRefillCount,
            features: action.response.features,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.GET_FILTERS_RESPONSE) {
        const stateUpdates = {
            hydrated: true,
            viewByOptions: action.response.filters,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.ENABLE_TAGGING) {
        return ObjectAssign({}, initialState, {
            hydrated: false,
            loading: true,
        });
    }

    if (action.type === Constants.TOGGLE_EDIT_MODE) {
        return ObjectAssign({}, state, {
            editMode: action.toggle,
        });
    }

    if (
        action.type === Constants.SAVE_DETAILS ||
        action.type === Constants.SAVE_FARM_DETAILS
    ) {
        return ObjectAssign({}, state, {
            loading: true,
            name: action.request.data.name,
            location: action.request.data.location,
        });
    }

    if (action.type === Constants.SAVE_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);
        const stateUpdates = {
            loading: false,
            showSaveSuccess: !action.err,
            error: validation.error,
            hasError: validation.hasError,
            help: validation.help,
        };

        if (action.response.hasOwnProperty("name")) {
            stateUpdates.name = action.response.name;
        }
        if (action.response.hasOwnProperty("location")) {
            stateUpdates.location = action.response.location;
        }

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.HIDE_DETAILS_SAVE_SUCCESS) {
        return ObjectAssign({}, state, {
            showSaveSuccess: false,
            showDetailsSaveSuccess: false,
        });
    }

    if (action.type === Constants.SHOW_CREATE_LINE) {
        return ObjectAssign({}, state, {
            showNewLine: true,
        });
    }

    if (action.type === Constants.HIDE_CREATE_LINE) {
        return ObjectAssign({}, state, {
            showNewLine: false,
        });
    }

    if (action.type === Constants.TOGGLE_TAB) {
        return ObjectAssign({}, state, {
            activeTab: action.activeTab,
        });
    }

    if (action.type === Constants.ADD_FARM_DETAILS) {
        // return ObjectAssign({}, state, {
        //     loading: true
        // });
    }

    if (action.type === Constants.ADD_FARM_DETAILS_RESPONSE) {
        if (action.response.error) {
            return ObjectAssign({}, state, {
                error: action.response.error,
                hydrated: true,
                loading: false,
            });
        }
        if (action.response._id) {
            window.location.href = `/farms/${action.response._id}`;
        }
        return ObjectAssign({}, state);
    }

    if (action.type === Constants.RESEND_EMAIL_RESPONSE) {
        return ObjectAssign({}, state, {
            emailSent: true,
        });
    }

    if (action.type === Constants.RESEND_EMAIL_ALERT_HIDE) {
        return ObjectAssign({}, state, {
            emailSent: false,
        });
    }

    if (action.type === Constants.SAVE_FARM_DETAILS_RESPONSE) {
        const validation = ParseValidation(action.response);

        const leases = action.response.leases.reverse();

        const stateUpdates = {
            showDetailsSaveSuccess: true,
            _id: action.response._id,
            hydrated: true,
            loading: false,
            showFetchFailure: !!action.err,
            error: validation.error,
            address1: action.response.address1,
            address2: action.response.address2,
            city: action.response.city,
            country: action.response.country,
            activities: action.response.activities || [],
            email: action.response.email,
            name: action.response.name,
            owner: action.response.owner,
            leases,
            setupFee: action.response.setupFee,
            state: action.response.state,
            zip: action.response.zip,
            stripeCustomerId: action.response.stripeCustomerId,
            hasPaymentMethod: action.response.hasPaymentMethod,
            taggingActive: action.response.taggingActive,
            taggingSetupInvoice: action.response.taggingSetupInvoice,
            taggingRefillThreshold: action.response.taggingRefillThreshold,
            taggingSetupFee: action.response.taggingSetupFee,
            taggingInvoiceAmount: action.response.taggingInvoiceAmount,
            refillSoftwareFee: action.response.refillSoftwareFee,
            taggingInvoices: action.response.taggingInvoices,
            shippingAddress: action.response.shippingAddress,
            totalPrinted: action.response.totalPrinted,
            distributorBrands: action.response.distributorBrands || [],
            growerNames: action.response.growerNames,
            photo: action.response.photo,
            hasCustomPaper: action.response.hasCustomPaper || false,
            customPaperRolls: action.response.customPaperRolls || [],
            customMobileRolls: action.response.customMobileRolls || [],
            nextMobileShipment: action.response.nextMobileShipment,
            nextIndustrialShipment: action.response.nextIndustrialShipment,
            mobileLaminatedRollRefillCount:
                action.response.mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount:
                action.response.sixMilMobileRollRefillCount,
            industrialSmallRollRefillCount:
                action.response.industrialSmallRollRefillCount,
            features: action.response.features,
        };

        return ObjectAssign({}, state, stateUpdates);
    }

    if (action.type === Constants.TOGGLE_SAVE_SUCCESS) {
        return ObjectAssign({}, state, {
            showSaveSuccess: action.showSaveSuccess,
        });
    }

    if (action.type === Constants.UPDATE_FEATURE_FLAGS) {
        console.log("loading");
        return ObjectAssign({}, state, {
            loading: true,
        });
    }

    if (action.type === Constants.UPDATE_FEATURE_FLAGS_RESPONSE) {
        return ObjectAssign({}, state, {
            activeTab: 0,
            loading: false,
            features: action.response.features,
            alert: "Feature Flags Updated",
        });
    }
    if (action.type === Constants.HIDE_ALERT) {
        return ObjectAssign({}, state, {
            alert: null,
        });
    }

    return state;
};

export default reducer;
