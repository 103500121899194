import React from "react";
import Switch from "react-switch";
import TextControl from "../../../../components/form/text-control.jsx";
import Spinner from "../../../../components/form/spinner.jsx";
import LinkState from "../../../../helpers/link-state";
import SelectControl from "../../../../components/form/select-control.jsx";
import Button from "../../../../components/form/button.jsx";
import CurrentInvoiceAmount from "./CurrentInvoiceAmount";
import Actions from "./actions";
import StateOptions from "./StateOptions.js";
import CustomPaperHistory from "./CustomPaperHistory.js";
import NextShipmentEdit from "./NextShipmentEdit.js";
import SelectNewPaper from "./SelectNewPaper.js";

class TaggingSetup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasPrinterChanged: false,
            newPaperType: null,
            newPaper: null,
            newStickerRolls: null,
            newBlackLineMobile: null,
            newPrinter: "",
            displayErrors: false,
            loading: false,
            nextMobileShipment: null,
            nextIndustrialShipment: null,
            mobileRollRefillCount: 0,
            mobileLaminatedRollRefillCount: 0,
            sixMilMobileRollRefillCount: 0,
            mobileStickerRollRefillCount: 0,
            blackLineMobileRefillCount: 0,
            refillRollCount: 12,
            industrialRollRefillCount: 0,
            industrialSmallRollRefillCount: 0,
            hasCustomPaper: false,
            addCustomPaper: null,
            addCustomMobilePaper: null,
            promotionalFee: null,
            paperSize: '7"',
            sendFullfillmentEmail: true,
            paperDiscount: false,
            printers: [],
            shippingAddress: {
                name: "",
                business: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "",
                zip: "",
            },
        };
    }

    componentWillMount() {
        const {
            shippingAddress,
            paperSize,
            sendFullfillmentEmail,
            paperDiscount,
            printers,
            nextMobileShipment,
            nextIndustrialShipment,
            mobileRollRefillCount,
            mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount,
            industrialRollRefillCount,
            industrialSmallRollRefillCount,
            mobileStickerRollRefillCount,
            blackLineMobileRefillCount,
            promotionalFee,
            taggingActive,
            hasCustomPaper,
        } = this.props;

        this.setState({
            shippingAddress: shippingAddress || this.state.shippingAddress,
            paperSize: paperSize || '7"',
            sendFullfillmentEmail:
                typeof sendFullfillmentEmail !== "undefined"
                    ? sendFullfillmentEmail
                    : true,
            paperDiscount: !!paperDiscount || false,
            printers: printers || [],
            nextMobileShipment: nextMobileShipment || null,
            nextIndustrialShipment: nextIndustrialShipment || null,
            mobileRollRefillCount: mobileRollRefillCount || null,
            mobileLaminatedRollRefillCount:
                mobileLaminatedRollRefillCount || null,
            sixMilMobileRollRefillCount: sixMilMobileRollRefillCount || null,
            industrialRollRefillCount: industrialRollRefillCount || null,
            industrialSmallRollRefillCount:
                industrialSmallRollRefillCount || null,
            mobileStickerRollRefillCount: mobileStickerRollRefillCount || null,
            blackLineMobileRefillCount: blackLineMobileRefillCount || null,
            promotionalFee: taggingActive !== true ? promotionalFee : null,
            hasCustomPaper,
        });
    }

    componentWillReceiveProps(nextProps) {
        const {
            shippingAddress,
            paperSize,
            sendFullfillmentEmail,
            paperDiscount,
            printers,
            nextMobileShipment,
            nextIndustrialShipment,
            mobileRollRefillCount,
            mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount,
            industrialRollRefillCount,
            industrialSmallRollRefillCount,
            mobileStickerRollRefillCount,
            blackLineMobileRefillCount,
            promotionalFee,
            taggingActive,
            hasCustomPaper,
        } = nextProps;

        this.setState({
            shippingAddress: shippingAddress || this.state.shippingAddress,
            paperSize: paperSize || '7"',
            sendFullfillmentEmail:
                typeof sendFullfillmentEmail !== "undefined"
                    ? sendFullfillmentEmail
                    : true,
            paperDiscount: paperDiscount || false,
            printers: printers || [],
            nextMobileShipment: nextMobileShipment || null,
            nextIndustrialShipment: nextIndustrialShipment || null,
            mobileRollRefillCount: mobileRollRefillCount || null,
            mobileLaminatedRollRefillCount:
                mobileLaminatedRollRefillCount || null,
            sixMilMobileRollRefillCount: sixMilMobileRollRefillCount || null,
            industrialRollRefillCount: industrialRollRefillCount || null,
            industrialSmallRollRefillCount:
                industrialSmallRollRefillCount || null,
            mobileStickerRollRefillCount: mobileStickerRollRefillCount || null,
            blackLineMobileRefillCount: blackLineMobileRefillCount || null,
            promotionalFee: taggingActive !== true ? promotionalFee : null,
            hasCustomPaper,
        });
    }

    handleSendEmailChange = (sendFullfillmentEmail) => {
        this.setState({ sendFullfillmentEmail });
    };

    handlePaperDiscountChange = (paperDiscount) =>
        this.setState({ paperDiscount });

    toggleCustomPaper = (hasCustomPaper) => {
        this.setState({ hasCustomPaper });
    };

    handleSubmit(event) {
        this.setState({
            loading: true,
        });
        event.preventDefault();
        event.stopPropagation();
        const {
            promotionalFee,
            paperSize,
            shippingAddress,
            sendFullfillmentEmail,
            paperDiscount,
            printers,
            nextMobileShipment,
            nextIndustrialShipment,
            mobileRollRefillCount,
            mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount,
            industrialRollRefillCount,
            industrialSmallRollRefillCount,
            mobileStickerRollRefillCount,
            blackLineMobileRefillCount,
            hasCustomPaper,
            addCustomPaper,
            addCustomMobilePaper,
        } = this.state;

        if (
            !printers.length ||
            !shippingAddress.name ||
            !shippingAddress.address1 ||
            !shippingAddress.city ||
            !shippingAddress.state ||
            !shippingAddress.country ||
            !shippingAddress.zip
        ) {
            console.log("setting error here");
            this.setState({
                displayErrors: true,
                loading: false,
            });
            return;
        }

        const customPaperRolls = this.props.customPaperRolls || [];

        if (addCustomPaper) {
            customPaperRolls.push({ date: new Date(), count: addCustomPaper });
        }
        const customMobileRolls = this.props.customMobileRolls || [];
        if (addCustomMobilePaper) {
            customMobileRolls.push({
                date: new Date(),
                count: addCustomMobilePaper,
            });
        }

        Actions.enableTagging(this.props._id, {
            printers,
            nextMobileShipment,
            nextIndustrialShipment,
            mobileRollRefillCount,
            mobileLaminatedRollRefillCount,
            sixMilMobileRollRefillCount,
            sixMilMobileRollRefillCount,
            industrialRollRefillCount,
            industrialSmallRollRefillCount,
            mobileStickerRollRefillCount,
            blackLineMobileRefillCount,
            promotionalFee,
            shippingAddress,
            paperSize,
            sendFullfillmentEmail,
            paperDiscount,
            hasCustomPaper,
            customPaperRolls,
            customMobileRolls,
        });
        this.props.toggleEdit();
    }

    addPrinter = () => {
        if (this.state.newPaperType && !this.state.newPaper) {
            alert("Please enter the number of rolls");
            return;
        }
        const printer = JSON.parse(
            JSON.stringify(
                this.props.printerOptions.find(
                    (p) => p.name === this.state.newPrinter
                )
            )
        );
        printer.paperType = this.state.newPaperType || null;
        printer.rollCount = this.state.newPaper || 0;
        printer.stickerRollCount = this.state.newStickerRolls || 0;
        printer.blackLineMobile = this.state.newBlackLineMobile || 0;

        const stateUpdate = {
            printers: this.state.printers.concat([printer]),
            newPrinter: "",
            newPaper: null,
            newPaperType: null,
            newBlackLineMobile: "",
            newStickerRolls: null,
            hasPrinterChanged: true,
        };

        if (this.state.newPaper) {
            if (printer.type === "mobile") {
                stateUpdate.nextMobileShipment = this.state.newPaperType;
                if (
                    this.state.newPaperType === "mobileLaminated" &&
                    !this.state.mobileLaminatedRollRefillCount
                ) {
                    stateUpdate.mobileLaminatedRollRefillCount = 15;
                }

                if (
                    this.state.newPaperType === "sixMilMobile" &&
                    !this.state.sixMilMobileRollRefillCount
                ) {
                    stateUpdate.sixMilMobileRollRefillCount = 20;
                }

                if (
                    this.state.newPaperType === "mobile" &&
                    !this.state.mobileRollRefillCount
                ) {
                    stateUpdate.mobileRollRefillCount = 12;
                }
            }
            if (printer.type === "industrial") {
                stateUpdate.nextIndustrialShipment = this.state.newPaperType;
                if (
                    this.state.newPaperType === "industrialSmall" &&
                    !this.state.industrialSmallRollRefillCount
                ) {
                    stateUpdate.industrialSmallRollRefillCount = 4;
                }
                if (
                    this.state.newPaperType === "industrial" &&
                    !this.state.industrialRollRefillCount
                ) {
                    stateUpdate.industrialRollRefillCount = 4;
                }
            }
        }
        if (this.state.newStickerRolls) {
            if (
                printer.type === "mobile" &&
                !this.state.mobileStickerRollRefillCount
            ) {
                stateUpdate.mobileStickerRollRefillCount = 5;
            }
        }

        if (
            this.state.newBlackLineMobile &&
            !this.state.blackLineMobileRefillCount
        ) {
            stateUpdate.blackLineMobileRefillCount = 4;
        }

        this.setState(stateUpdate);
    };

    removePrinter = (index) => {
        const printersUpdate = JSON.parse(JSON.stringify(this.state.printers));

        printersUpdate.splice(index, 1);

        const stateUpdate = {
            printers: printersUpdate,
            hasPrinterChanged: true,
        };

        if (!printersUpdate.find((p) => p.name === "TSC DA220")) {
            stateUpdate.blackLineMobileRefillCount = null;
        }

        if (!printersUpdate.find((p) => p.type === "mobile")) {
            stateUpdate.nextMobileShipment = null;
            stateUpdate.mobileRollRefillCount = null;
            stateUpdate.mobileLaminatedRollRefillCount = null;
            stateUpdate.mobileStickerRollRefillCount = null;
            stateUpdate.sixMilMobileRollRefillCount = null;
        }

        if (!printersUpdate.find((p) => p.type === "industrial")) {
            stateUpdate.nextIndustrialShipment = null;
            stateUpdate.industrialRollRefillCount = null;
            stateUpdate.industrialSmallRollRefillCount = null;
        }
        this.setState(stateUpdate);
    };
    render() {
        const { displayErrors } = this.state;
        const { country, paperCosts } = this.props;

        return (
            <div>
                <br />
                <div className="panel panel-default">
                    <CurrentInvoiceAmount
                        printers={this.state.printers}
                        promotionalFee={this.state.promotionalFee}
                        taggingActive={this.props.taggingActive}
                        farmId={this.props._id}
                    />
                    <div className="panel-heading">Overrides</div>
                    <div className="panel-body">
                        <TextControl
                            name="promotionalFee"
                            label="Promotional Setup Fee (overrides default printer + paper costs)"
                            type="number"
                            value={this.state.promotionalFee}
                            onChange={LinkState.bind(this)}
                        />

                        <div className="form-group">
                            <label className="control-label">
                                Send fullfillment email?
                            </label>
                            <div>
                                <Switch
                                    onChange={this.handleSendEmailChange}
                                    checked={this.state.sendFullfillmentEmail}
                                    onColor="#5084F1"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label">
                                Paper Discount
                            </label>
                            <div>
                                <Switch
                                    onChange={this.handlePaperDiscountChange}
                                    checked={this.state.paperDiscount}
                                    onColor="#5084F1"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">Printers</div>
                    <div className="panel-body">
                        <h3>Current Printers: </h3>
                        <div className="row">
                            <div className="col-sm-6">
                                {(this.state.printers.length && (
                                    <ul>
                                        {this.state.printers.map(
                                            (printer, i) => {
                                                const paper = [
                                                    printer.rollCount &&
                                                        `${printer.rollCount} tag rolls`,
                                                    printer.stickerRollCount &&
                                                        `${printer.stickerRollCount} sticker rolls`,
                                                    printer.blackLineMobile &&
                                                        `${printer.blackLineMobile} black line mobile rolls`,
                                                ]
                                                    .filter(Boolean)
                                                    .join(", ");

                                                return (
                                                    <li>
                                                        {[
                                                            `${printer.name} (${printer.type})`,
                                                            paper,
                                                        ]
                                                            .filter(Boolean)
                                                            .join(" + ")}
                                                        &nbsp;
                                                        <button
                                                            onClick={() => {
                                                                this.removePrinter(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            X
                                                        </button>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                )) || <p>No printers allocated.</p>}
                            </div>
                            <div className="col-sm-6">
                                {this.props.taggingActive === true &&
                                    this.state.hasPrinterChanged && (
                                        <div className="alert-warning alert alert-static">
                                            <p>
                                                <strong>WARINING:</strong>{" "}
                                                removing printers will not
                                                refund users this needs to be
                                                done in stripe, however adding
                                                printers will charge users
                                                automagically.
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <SelectControl
                                name="newPrinter"
                                label="Add a Printer"
                                value={this.state.newPrinter}
                                onChange={(e) => {
                                    this.setState({
                                        newPrinter: e.target.value,
                                        newPaper: null,
                                        newPaperType: null,
                                    });
                                }}
                                disabled={this.state.loading}
                            >
                                <option value="">-- Select One -- </option>
                                {this.props.printerOptions.map((o) => (
                                    <option
                                        value={o.name}
                                    >{`${o.name} (${o.type})`}</option>
                                ))}
                            </SelectControl>
                        </div>

                        <div className="col-sm-6">
                            <SelectNewPaper
                                state={this.state}
                                parentThis={this}
                                country={country}
                                printerOptions={this.props.printerOptions}
                            />
                        </div>
                        <div className="col-sm-12">
                            <Button
                                type="submit"
                                inputClasses={{ "btn-secondary": true }}
                                onClick={this.addPrinter}
                            >
                                + Add Printer
                            </Button>
                        </div>
                        <br />
                        <br />
                        {displayErrors && !this.state.printers.length && (
                            <p className="text-danger">
                                Please add at least one printer.
                            </p>
                        )}
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading">Paper Replenishment</div>
                    <div className="panel-body">
                        <NextShipmentEdit
                            state={this.state}
                            parentThis={this}
                            country={country}
                        />

                        <h3>Custom Paper</h3>
                        <p>
                            Log custom paper shipments here. When farms are
                            through 50% of their last shipment our team will be
                            notified to replenish and bill manually.
                        </p>
                        <div className="form-group">
                            <label className="control-label">
                                Has Custom Paper?
                            </label>

                            <div>
                                <Switch
                                    onChange={this.toggleCustomPaper}
                                    checked={this.state.hasCustomPaper}
                                    onColor="#5084F1"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                />
                            </div>
                        </div>
                        {this.state.hasCustomPaper && (
                            <>
                                {(this.props.customPaperRolls &&
                                    this.props.customPaperRolls.length && (
                                        <>
                                            <h4>Custom Industrial Shipments</h4>
                                            <CustomPaperHistory
                                                customPaperRolls={
                                                    this.props.customPaperRolls
                                                }
                                                paperCosts={paperCosts}
                                                type="industrial"
                                            />
                                        </>
                                    )) ||
                                    null}

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextControl
                                            name="addCustomPaper"
                                            label="# of Custom Industrial Rolls"
                                            type="number"
                                            value={this.state.addCustomPaper}
                                            onChange={LinkState.bind(this)}
                                        />
                                    </div>
                                </div>
                                {(this.props.customMobileRolls &&
                                    this.props.customMobileRolls.length && (
                                        <>
                                            <h4>Custom Mobile Shipments</h4>
                                            <CustomPaperHistory
                                                customPaperRolls={
                                                    this.props.customMobileRolls
                                                }
                                                paperCosts={paperCosts}
                                                type="mobileCustom"
                                            />
                                        </>
                                    )) ||
                                    null}
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextControl
                                            name="addCustomMobilePaper"
                                            label="# of Custom Mobile Rolls"
                                            type="number"
                                            value={
                                                this.state.addCustomMobilePaper
                                            }
                                            onChange={LinkState.bind(this)}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">Shipping Address</div>
                    <div className="panel-body">
                        <TextControl
                            name="shippingAddress.name"
                            label="Name*"
                            value={this.state.shippingAddress.name}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.shippingAddress.name && (
                            <p className="text-danger">Name is required.</p>
                        )}
                        <TextControl
                            name="shippingAddress.business"
                            label="Buisness"
                            value={this.state.shippingAddress.business}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        <TextControl
                            name="shippingAddress.address1"
                            label="Address 1*"
                            value={this.state.shippingAddress.address1}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors &&
                            !this.state.shippingAddress.address1 && (
                                <p className="text-danger">
                                    Address 1 is required.
                                </p>
                            )}
                        <TextControl
                            name="shippingAddress.address2"
                            label="Address 2"
                            value={this.state.shippingAddress.address2}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />

                        <TextControl
                            name="shippingAddress.city"
                            label="City*"
                            value={this.state.shippingAddress.city}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.shippingAddress.city && (
                            <p className="text-danger">City is required.</p>
                        )}
                        <SelectControl
                            name="shippingAddress.state"
                            label="State*"
                            value={this.state.shippingAddress.state}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        >
                            <StateOptions
                                country={this.state.shippingAddress.country}
                            />
                        </SelectControl>
                        {displayErrors && !this.state.shippingAddress.state && (
                            <p className="text-danger">State is required.</p>
                        )}
                        <TextControl
                            name="shippingAddress.zip"
                            label="Zip Code *"
                            value={this.state.shippingAddress.zip}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        />
                        {displayErrors && !this.state.shippingAddress.zip && (
                            <p className="text-danger">Zip Code is required.</p>
                        )}
                        <SelectControl
                            name="shippingAddress.country"
                            label="Country*"
                            value={this.state.shippingAddress.country}
                            onChange={LinkState.bind(this)}
                            disabled={this.state.loading}
                        >
                            <option value="">- Select One - </option>
                            <option value="Canada">Canada</option>
                            <option value="Mexico">Mexico</option>
                            <option value="USA">USA</option>
                            <option value="Australia">Australia</option>
                        </SelectControl>
                        {displayErrors &&
                            !this.state.shippingAddress.country && (
                                <p className="text-danger">
                                    Country is required.
                                </p>
                            )}
                    </div>
                </div>
                <Button
                    type="submit"
                    inputClasses={{ "btn-primary": true }}
                    onClick={this.handleSubmit.bind(this)}
                    disabled={this.state.loading}
                >
                    {this.props.taggingActive
                        ? "Update Tagging Subscription"
                        : "Enable Tagging Subscription"}
                    <Spinner space="left" show={this.state.loading} />
                </Button>
            </div>
        );
    }
}

export default TaggingSetup;
