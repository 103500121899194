import Footer from './footer.jsx';
import Navbar from './navbar.jsx';
import NotFound from './not-found.jsx';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FarmDetails from './details/index.jsx';
import FarmSearch from '../farms/farms/search/index.jsx';

const App = () => {
	return (
		<div>
			{/* <CheckPermission /> */}
			<Route component={Navbar} />
			<Switch>
				<Route path="/manage" exact component={FarmSearch} />
				<Route path="/manage/:id" component={FarmDetails} />

				<Route component={NotFound} />
			</Switch>
			<Footer />
		</div>
	);
};

export default App;
