import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import jsonFetch from '../../../helpers/json-fetch';

export default function SsoComponent(props) {
	const [error, setError] = useState(null);
	useEffect(() => {
		const {
			match: {
				params: { token, farmId },
			},
		} = props;

		// Log user in
		sessionStorage.setItem('jwtToken', token);

		const navigateToFarm = async () => {
			jsonFetch(
				{ method: 'GET', url: '/api/users/my', query: null },
				(err, response) => {
					if (err) {
						setError(error.message);
					}

					return (window.location.href = `/manage/${farmId}`);
				}
			);
		};

		navigateToFarm();
	}, []);
	return (
		<section className="container">
			<Helmet>
				<title>{(error && 'Error') || 'Loading...'}</title>
			</Helmet>
			<div className="container">
				{(error && (
					<>
						<h1>Error</h1>
						<p>{error}</p>
					</>
				)) || <h1>Loading...</h1>}
			</div>
		</section>
	);
}
