import React from "react";

const lookupTypes = {
    mobile: "mobile",
    mobileLaminated: "mobileLaminated",
    sixMilMobile: "sixMilMobile",
    industrial: "industrial",
    industrialSmall: "industrialSmall",
    mobileSticker: "mobileStickerRoll",
    mobileStickerRoll: "mobileStickerRoll",
    blackLineMobile: "blackLineMobile",
};

const typeDisplay = {
    mobile: "mobile",
    mobileLaminated: "laminated mobile",
    sixMilMobile: "6mil mobile",
    industrial: '7" industrial',
    industrialSmall: "small industrial",
    mobileStickerRoll: "desktop sticker",
    blackLineMobile: "blackline desktop",
};

export const getCost = (paperDiscount, paperCosts, type, count) => {
    const packCost = paperDiscount
        ? paperCosts[type].distributorCost
        : paperCosts[type].cost;

    const cost = (count / paperCosts[type].rollsPerPack) * packCost;

    let shippingCost = type.toLowerCase().includes("industrial")
        ? paperCosts.shipping.industrialPaper
        : paperCosts.shipping.mobilePaper;

    if (type === "blackLineMobile") {
        shippingCost = paperCosts.shipping.ausShipping;
    }

    console.log("hi", cost, shippingCost);
    return cost + shippingCost;
};
const getRefillCosts = (type, props, refillInfo) => {
    let rollCount = 0;
    let nextShipment = refillInfo.nextShipmentType || type;

    if (
        type === "mobile" &&
        (props.mobileRollRefillCount ||
            props.mobileLaminatedRollRefillCount ||
            props.sixMilMobileRollRefillCount)
    ) {
        rollCount =
            refillInfo.refillRollCount ||
            props.mobileRollRefillCount ||
            props.mobileLaminatedRollRefillCount ||
            props.sixMilMobileRollRefillCount;
    }

    if (
        type === "industrial" &&
        (props.industrialRollRefillCount ||
            props.industrialSmallRollRefillCount)
    ) {
        rollCount =
            props.industrialRollRefillCount ||
            props.industrialSmallRollRefillCount;
        nextShipment = props.nextIndustrialShipment || type;
    }

    if (type === "mobileSticker" && props.mobileStickerRollRefillCount) {
        rollCount = props.mobileStickerRollRefillCount;
    }

    if (type === "blackLineMobile" && props.blackLineMobileRefillCount) {
        rollCount = props.blackLineMobileRefillCount;
    }

    if (rollCount) {
        const cost = getCost(
            props.paperDiscount,
            props.paperCosts,
            lookupTypes[nextShipment],
            rollCount
        );

        return cost.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    }

    return null;
};

const getRefillCountByType = (type, props, refillInfo) => {
    if (type === "mobile") {
        if (
            props.mobileRollRefillCount ||
            props.mobileLaminatedRollRefillCount ||
            props.sixMilMobileRollRefillCount
        ) {
            return `${refillInfo.refillRollCount} ${
                typeDisplay[refillInfo.nextShipmentType]
            } rolls`;
        }
    }

    if (type === "industrial") {
        if (
            props.industrialRollRefillCount ||
            props.industrialSmallRollRefillCount
        ) {
            return `${refillInfo.refillRollCount} ${
                typeDisplay[refillInfo.nextShipmentType]
            } rolls`;
        }
    }

    if (type === "mobileSticker" && props.mobileStickerRollRefillCount) {
        return `${refillInfo.refillRollCount} ${
            typeDisplay[refillInfo.nextShipmentType] ||
            refillInfo.nextShipmentType
        } rolls`;
    }

    if (type === "blackLineMobile" && props.blackLineMobileRefillCount) {
        return `${refillInfo.refillRollCount} ${
            typeDisplay[refillInfo.nextShipmentType]
        } rolls`;
    }

    return null;
};

export default function NextShipment({ taggingRefillThreshold, ...rest }) {
    const rows = ["mobile", "industrial", "mobileSticker", "blackLineMobile"];

    const paperType = {
        mobile: "Mobile Tags",
        industrial: "Industrial Tags",
        mobileSticker: "Mobile Sticker Rolls",
        blackLineMobile: "Black Line Mobile Tags",
    };

    if (!taggingRefillThreshold) {
        return null;
    }

    return (
        <table className="billingTable">
            <tr>
                <th>Paper Type</th>
                <th>Reminder @ count</th>
                <th>Shipment @ count</th>
                <th>Shipment</th>
                <th>Cost</th>
            </tr>
            {rows.map((key) => {
                return (
                    (taggingRefillThreshold[key] &&
                        getRefillCountByType(
                            key,
                            rest,
                            taggingRefillThreshold[key]
                        ) && (
                            <tr>
                                <th>{paperType[key]}</th>
                                <td>
                                    {
                                        taggingRefillThreshold[key]
                                            .nextReminderCount
                                    }
                                </td>
                                <td>
                                    {
                                        taggingRefillThreshold[key]
                                            .nextFinalizedCount
                                    }
                                </td>
                                <td>
                                    {getRefillCountByType(
                                        key,
                                        rest,
                                        taggingRefillThreshold[key]
                                    )}
                                </td>
                                <td>
                                    {getRefillCosts(
                                        key,
                                        rest,
                                        taggingRefillThreshold[key]
                                    )}
                                </td>
                            </tr>
                        )) ||
                    null
                );
            })}
        </table>
    );
}
