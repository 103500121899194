import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const propTypes = {
	data: PropTypes.array,
};

function Results(props) {
	const history = useHistory();
	const rows = props.data.map((record) => {
		const handleRowClick = () => {
			history.push(`/${window.location.pathname.split('/')[1]}/${record._id}`);
		};
		const detailLink = `${window.location.pathname.split('/')[1]}/${
			record._id
		}`;

		return (
			<tr key={record._id} onClick={handleRowClick}>
				<td>{record.name}</td>
				<td>{record.type}</td>
				<td style={{ textAlign: 'center' }}>
					{record.paperDiscount ? (
						<i className="fa fa-check-circle text-success"></i>
					) : (
						''
					)}
				</td>
				<td>
					{record.city}, {record.state} {record.country}
				</td>
				<td>
					<Link className="btn btn-default btn-sm" to={detailLink}>
						View Details
					</Link>
				</td>
			</tr>
		);
	});

	return (
		<div className="table-responsive">
			<table className="table table-striped table-results">
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Paper Discount</th>
						<th>Location</th>
						<th />
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
}

Results.propTypes = propTypes;

export default Results;
