import FluxConstant from "flux-constant";

export default FluxConstant.set([
    "DELETE",
    "DELETE_LEASE",
    "DELETE_LEASE_RESPONSE",
    "DELETE_RESPONSE",
    "GET_DETAILS",
    "GET_DETAILS_RESPONSE",
    "GET_FILTERS",
    "GET_FILTERS_RESPONSE",
    "HIDE_DETAILS_SAVE_SUCCESS",
    "HIDE_LEASE_SAVE_SUCCESS",
    "NEW_BAG",
    "NEW_BAG_RESPONSE",
    "NEW_LEASE",
    "NEW_LEASE_RESPONSE",
    "NEW_LINE",
    "NEW_LINE_RESPONSE",
    "NEW_TRAY",
    "NEW_TRAY_RESPONSE",
    "SAVE_DETAILS",
    "SAVE_DETAILS_RESPONSE",
    "TOGGLE_EDIT_MODE",
    "GET_USER",
    "GET_USER_RESPONSE",
    "SAVE_ADDRESS",
    "SAVE_ADDRESS_RESPONSE",
    "SAVE_FARM_DETAILS",
    "SAVE_FARM_DETAILS_RESPONSE",
    "TOGGLE_SAVE_SUCCESS",
]);
